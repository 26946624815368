.ns_com_payment_methods_dialog_edit_view_main .payment_method_form {
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.4;
}

.ns_com_payment_methods_dialog_edit_view_main .payment_method_form .set_as_default_wrapper {
  margin-top: var(--spacing-xxl);
}

.ns_com_payment_methods_dialog_edit_view_main .payment_method_form .set_as_default_wrapper .set_as_default_description {
  margin-top: var(--spacing-xs);
  color: var(--color-gray4);
  font-size: var(--font-size2);
  line-height: 1.3;
}

.ns_com_payment_methods_dialog_edit_view_main .payment_method_form .set_as_default_wrapper .checkbox {
  font-weight: 500;
}

.ns_com_payment_methods_dialog_edit_view_main .payment_method_form .set_as_default_wrapper .checkbox.adm-checkbox-checked svg {
  color: var(--color-primary);
}

/*# sourceMappingURL=index.a3b605ee.css.map */
